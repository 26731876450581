import Ajv from 'ajv';

import { AppConfig, OktaAuthService } from '../types';

export interface OktaConfig {
  app: AppConfig;
  authConfig: OktaAuthService;
}

const schema = {
  type: 'object',
  required: ['app', 'authConfig'],
  properties: {
    app: {
      type: 'object',
      required: ['key', 'name'],
      properties: {
        key: {
          type: 'string',
        },
        env: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        properties: {
          type: 'array',
        },
      },
    },
    authConfig: {
      type: 'object',
      required: ['issuer', 'clientId'],
      properties: {
        issuer: {
          type: 'string',
        },
        clientId: {
          type: 'string',
        },
        redirectUri: {
          type: 'string',
        },
      },
    },
  },
};

const ajv = new Ajv({ allErrors: true });

function validateConfig(config: OktaConfig) {
  const valid = ajv.validate(schema, config);
  if (!valid) {
    ajv.errors?.forEach(err => console.error('AppRouter ' + err.message));
    console.error(
      'See https://screentone.djnews.tools/addons/auth-wrapper for more details.'
    );
  }

  return valid;
}

export { validateConfig };
