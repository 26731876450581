import React from 'react';
import {
  Illustration,
  PageWrapper,
  Grid,
  Typography,
  Textblock,
} from '@screentone/core';
import get from 'lodash/get';

import ErrorMessage from '../../components/ErrorMessage';
import RequestAccess from '../../components/RequestAccess';
import { AppConfig } from '../../types';
import { ERRORS } from './Error.constants'; //  USER_REQUEST_OPTIONS

import './Error.styles.css';

interface ErrorPageProps {
/**
* Type.
* [See App Props page for details](./?path=/docs/authrouter-app-props--page)'
*/
  type: keyof typeof ERRORS;
  /**
  * Base details of your app, like your App name.
  * [See App Props page for details](./?path=/docs/authrouter-app-props--page)'
  */
  app?: AppConfig;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ type, app }) => {
  const error = ERRORS[type] || ERRORS.default;
  let requestAccess = null;
  let RequestAccessOverride = null;

  if (error.status === 403 && app && React.isValidElement(app.RequestAccessOverride)) {
    RequestAccessOverride = app.RequestAccessOverride;
  }

  if (error.status === 403 && app && app.requestAccess) {

    const urlSearchParams = new URLSearchParams(window.location.search);

    const name = urlSearchParams.get('name');
    const email = urlSearchParams.get('email');
    const autofill: any = {
      name: name || get(app, 'requestAccess.autofill.name'),
      email: email || get(app, 'requestAccess.autofill.name'),
    };

    requestAccess = Object.assign(
      {
        ...app.requestAccess,
      },
      {
        autofill,
        properties: app.properties,
        appName: app.name,
        appEnv: app.env,
      }
    );
  }
  
  let { name, message, title } = error;
  if ('maintenance' === type && app && app.maintenance && app.maintenance.message) {
    message += ` ${app.maintenance.message}`;
  }
  
  return (
    <PageWrapper>
      <Grid>
        <Grid.Row>
          <Grid.Col xs={12} md={requestAccess ? 6 : 12}>
            <Illustration
              name={name}
              className="st-addon-okta-error-page-illustration"
            />
            <Typography align="center" variant="header1">{title}</Typography>
            <Textblock className="st-addon-okta-error-page-text">
              <ErrorMessage message={message} app={app} />
            </Textblock>
          </Grid.Col>
          {RequestAccessOverride ? RequestAccessOverride : requestAccess ? <RequestAccess {...requestAccess} /> : null }
        </Grid.Row>
      </Grid>
    </PageWrapper>
  );
};

export default ErrorPage;
export { ErrorPage, ErrorPageProps };
