import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { User } from '../../types';

function checkUserAccess(
  user: User,
  permission: string[],
  userAccessKey: string = 'app_roles'
) {
  if (!user) {
    return false;
  }

  const isUserTester = get(user, 'app_tester', false);
  // @ts-ignore
  const userAppScope: string[] = isUserTester
    ? []
    : get(user, userAccessKey, []);

  return !isEmpty(permission.filter(val => userAppScope.includes(val) || user[val]));  
}

export { checkUserAccess };
