import React from 'react';
import { HeaderBar } from '@screentone/core';
import { NotificationsMenuProps } from '../../../types';

const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  notifications,
}) => {
  const { items, footer, ...props } = notifications;
  return (
    <HeaderBar.Notifications {...props}>
      {items &&
        items.map(({ text, ...itemProps }, i) => {
          return (
            <HeaderBar.Notifications.Item
              key={`Notifications.Item.${i}`}
              {...itemProps}
            >
              {text}
            </HeaderBar.Notifications.Item>
          );
        })}
      {footer && (
        <HeaderBar.Notifications.Footer {...footer}>
          {footer.text}
        </HeaderBar.Notifications.Footer>
      )}
    </HeaderBar.Notifications>
  );
};

export default NotificationsMenu;
