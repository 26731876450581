import React, { useEffect, useState } from 'react';
import { useMode } from '@screentone/core';
import { useAuth } from '../../providers/Auth/Auth.provider';
import { PageWrapperProps } from '../../types';

const PageWrapper: React.FC<PageWrapperProps> = ({
  PageWrapperElement,
  children,
  ...props
}) => {
  const [appMode, setApdMode] = useState('');
  const { enableThemeOptions, user } = useAuth();
  const { mode, updateMode } = useMode();

  useEffect(
    () => {
      if (appMode === 'light') updateMode(appMode);
    },
    [appMode],
  );

  if (user && !appMode && mode !== 'light' && !enableThemeOptions) {
    setApdMode('light')
  }

  if (PageWrapperElement) {
    return <PageWrapperElement {...props}>{children}</PageWrapperElement>;
  }

  return (
    <div className="st_content" {...props}>
      {children}
    </div>
  );
};

export default PageWrapper;
