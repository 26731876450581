import React, { useEffect, useState, useRef } from 'react';
import { PageWrapper, Loader, Typography } from '@screentone/core';
import { Maybe } from '../../types';

interface LoadingProps {
 /**
 * Delay in loading the loader, to pervert the screen from flashing.
 */
  appearIn?: number;
  /**
   * The text displayed under the loading spinner.
   */
  text?: string;
}

const LoadingPage: React.FC<LoadingProps> = ({
  appearIn = 800,
  text = 'Loading...',
  ...props
}) => {
  const [loaded, setLoaded] = useState(appearIn === 0);
  const timeoutRef = useRef<Maybe<NodeJS.Timeout>>(null);

  useEffect(() => {
    if (appearIn === 0) return;

    timeoutRef.current = setTimeout(() => setLoaded(true), appearIn);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [appearIn]);

  if (!loaded) {
    return null;
  }

  return (
    <PageWrapper {...props}>
      <Typography align="center" style={{ marginTop: '20%' }}>
        <Loader />
      </Typography>
      <Typography color="asphalt" align="center" margin={{ vertical: 'md' }}>
        {text}
      </Typography>
    </PageWrapper>
  );
};

export default LoadingPage;
export { LoadingPage };
