import React from 'react';
import get from 'lodash/get';
import { AppConfig } from '../../types';

interface ErrorMessageProps {
  app?: AppConfig;
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ app, message }) => {
  const appName = `${get(app, 'name', null) || ' development'} team`;
  return (
    <p>
      {message &&
        `${message.replace(
          '#{app.name}',
          (app && app.name) || ' this page.'
        )} `}
      Please contact the{' '}
      {app && app.slack && (
        <a
          href={app.slack.url}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="slack-link"
        >
          #{app.slack.name} team on slack
        </a>
      )}
      {(!app || (app && !app.slack)) && appName} if you need further assistance.
    </p>
  );
};

export default ErrorMessage;
export { ErrorMessage, ErrorMessageProps};
