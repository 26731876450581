import { useState } from 'react';
import { ActionMeta } from 'react-select';
import {
  SimpleInputHook,
  SelectPropertyProps,
  SelectPermissionProps,
} from '../../types';

interface ReactSelectProps {
  isMulti: boolean;
  name: string;
  options?: any[];
  value: any[];
  onChange: (_e: any, d: ActionMeta<any>) => void;
}

export const useSimpleInput = (
  name: string,
  init?: string
): SimpleInputHook => {
  const [value, setValue] = useState(init || '');
  return {
    name,
    value,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setValue(e.target.value),
  };
};

export const useSelections = (
  defaultPublications: SelectPropertyProps[] = [],
  defaultRoles: SelectPermissionProps[] = [],
  defaultProperties: string[]
) => {
  const defaultPubs = defaultPublications.filter(p =>
    defaultProperties.includes(p.value)
  );
  const [publications, setPublications] = useState(defaultPubs);

  /**
   * we build default permissions by iterating through each role
   * we make a validation hashmap ensuring default publications
   * have a publication defined in the app object
   * then we compare permissions that are set to default to populate
   * automatically if a publication is specified and that publication is
   * in the validation hashmap OR there is no publication assigned to the role
   */

  const validProperties: { [key: string]: boolean } = {};
  defaultPubs.forEach((p: SelectPropertyProps) => {
    validProperties[p.value] = false;
  });
  defaultProperties.forEach(pub => {
    if (validProperties[pub] === false) validProperties[pub] = true;
  });

  const defaultPerms: any[] = [];
  defaultRoles.forEach(role => {
    if (role.default && (!role.property || validProperties[role.property])) {
      defaultPerms.push(role);
    }
  });

  const [roles, setRoles] = useState(defaultPerms);
  return [
    {
      isMulti: true,
      name: 'publications',
      options: defaultPublications,
      value: publications,
      onChange: (_e: object, d: any) => {
        switch (d.action) {
          case 'select-option':
            setPublications(publications.concat(d.option));
            // eslint-disable-next-line no-case-declarations
            const addedDefaultPermisions = defaultRoles.filter(
              p => p.property === d.option.value && p.default === true
            );
            setRoles(roles.concat(addedDefaultPermisions));
            break;
          case 'remove-value':
            setPublications(
              publications.filter(o => d.removedValue.value !== o.value)
            );
            setRoles(roles.filter(p => p.property !== d.removedValue.value));
            break;
          case 'clear':
            setPublications([]);
            setRoles([]);
            break;
          default:
            break;
        }
      },
    } as ReactSelectProps,
    {
      isMulti: true,
      name: 'roles',
      value: roles,
      onChange: (_e: any, d: any) => {
        switch (d.action) {
          case 'select-option':
            setRoles(roles.concat(d.option));
            break;
          case 'remove-value':
            setRoles(roles.filter(o => d.removedValue.value !== o.value));
            break;
          case 'clear':
            setRoles([]);
            break;
          default:
            break;
        }
      },
    } as ReactSelectProps,
  ];
};
