import React, { useState } from 'react';
import Select from 'react-select';
import {
  Box,
  Grid,
  Typography,
  Button,
  FormLabel,
  Input,
  Textarea,
} from '@screentone/core';

import { RequestAccessProps, RequestErrorProps } from '../../types';
import { useSimpleInput, useSelections } from './RequestAccess.hooks';
import { getSlackRequestBody, getRequestBody } from './RequestAccess.utils';
import { SelectPermissionProps, SelectPropertyProps } from '../../types';
import { EMAIL_REGEX, SLACK_HOOK_REGEX } from './RequestAccess.constants';
import './RequestAccess.styles.css';

const RequestAccess: React.FC<RequestAccessProps> = props => {
  const {
    url,
    autofill = {} as any,
    defaultProperties = [],
    options = {},
    appName,
    appEnv,
  } = props;
  const propertyOptions = (props.properties || []).map(p => ({
    value: p.key,
    label: p.name,
    default: p.default || false,
  })) as SelectPropertyProps[];
  const permissionOptions = (props.roles || []).map(p => ({
    value: p.key,
    label: p.name,
    property: p.property || null,
    default: p.default || false,
  })) as SelectPermissionProps[];

  const name = useSimpleInput('name', autofill.name);
  const email = useSimpleInput('email', autofill.email);
  const justification = useSimpleInput('justification');
  const [properties, roles] = useSelections(
    propertyOptions,
    permissionOptions,
    defaultProperties
  );
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState<RequestErrorProps>({});

  if (submitted) {
    return (
      <Box
        padding={{ bottom: 'md' }}
        className="st_RequestAccessForm st_AccessSubmitted"
      >
        <Grid padding={{ all: 'md' }}>
          <Typography
            color="ink"
            size="xl"
            align="center"
            margin={{ top: 'xl' }}
          >
            Thank you!
          </Typography>
          <Typography color="asphalt" size="md" align="center">
            Someone will review your request.
          </Typography>
        </Grid>
      </Box>
    );
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const nameError = name.value.length === 0;
    const emailError = !EMAIL_REGEX.test(email.value);
    const propertiesError =
      propertyOptions.length !== 0 && properties.value.length === 0;
    const justificationError = justification.value.length === 0;
    setErrors({
      name: nameError,
      email: emailError,
      properties: propertiesError,
      justification: justificationError,
    });

    if (
      url &&
      !nameError &&
      !emailError &&
      !propertiesError &&
      !justificationError
    ) {
      const body = JSON.stringify(
        SLACK_HOOK_REGEX.test(url)
          ? getSlackRequestBody(
              roles,
              properties,
              justification,
              name,
              email,
              appName,
              appEnv
            )
          : getRequestBody(name, email, justification, roles, properties)
      );
    
      interface opts {
        body: any;
        method?: string;
        mode?: string;
        header?: any
      }

      const baseOptions: opts = {
        body,
        method: 'POST',
      }
      
      if (url.startsWith('https://hooks.slack.com/services')) {
        baseOptions.mode = 'no-cors';
      } else {
        baseOptions.header = { 'Content-Type': 'application/json' };
      }

      fetch(
        url,
        Object.assign(baseOptions, options) as object
      )
        .then(res => {
          if (res.type === 'opaque') {
            console.warn('Response payload was blocked.', res);
            setSubmitted(true);
          } else if (res.status === 200) {
            res
              .json()
              .then(data => {
                if (data.status === 200) {
                  setSubmitted(true);
                } else {
                  console.warn('Warning submitting request:', data);
                }
              })
              .catch(err =>
                console.error('Error submitting request (001):', err)
              );
          } else {
            console.error('Error submitting request (002):', res);
            setErrors({ email: true });
          }
        })
        .catch(err => console.error('invalid response', err));
    }
  };

  const pubKeys = properties.value.map(p => p.key);

  let publicationClasses = 'st_RequestAccessField';
  if (errors.properties) {
    publicationClasses = publicationClasses.concat(' st_AccessInvalidField');
  }
  let justificationClasses = 'st_RequestAccessField';
  if (errors.justification) {
    justificationClasses = justificationClasses.concat(
      ' st_AccessInvalidField'
    );
  }

  return (
    <Box padding={{ bottom: 'xs' }} className="st_RequestAccessForm">
      <Box.Title>Request Access</Box.Title>
      <Box.Content padding={{ horizontal: 'mlg', vertical: 'sm' }}>
        <Grid>
          <Grid.Row>
            <FormLabel margin={{ top: 'md' }} label="Name *" fullWidth>
              <Input
                {...name}
                error={errors.name}
                placeholder="Your full name"
              />
            </FormLabel>
          </Grid.Row>
          <Grid.Row>
            <FormLabel margin={{ top: 'md' }} label="Email *" fullWidth>
              <Input
                {...email}
                error={errors.email}
                placeholder="Company email address"
              />
            </FormLabel>
          </Grid.Row>
          {propertyOptions.length > 0 && (
            <Grid.Row>
              <FormLabel margin={{ top: 'md' }} label="Properties *" fullWidth>
                <Select
                  {...properties}
                  className={publicationClasses}
                  placeholder="Search properties..."
                />
              </FormLabel>
            </Grid.Row>
          )}
          {permissionOptions.length > 0 && (
            <Grid.Row>
              <FormLabel margin={{ top: 'md' }} label="Roles" fullWidth>
                <Select
                  {...roles}
                  options={permissionOptions.filter(
                    (p: any) => pubKeys.includes(p.property) || !p.property
                  )}
                  className="st_RequestAccessField"
                  placeholder="Search roles..."
                />
              </FormLabel>
            </Grid.Row>
          )}
          <Grid.Row>
            <FormLabel
              margin={{ top: 'md' }}
              label="Business Justification *"
              fullWidth
            >
              <Textarea
                {...justification}
                className={justificationClasses}
                error={errors.justification}
                placeholder="Business justification summary"
              />
            </FormLabel>
          </Grid.Row>
          <Grid.Row fullWidth className="st_RequestAccessButtonContainer">
            <Button
              className="st_RequestAccessButton"
              onClick={handleSubmit}
              primary
            >
              Submit Request
            </Button>
          </Grid.Row>
        </Grid>
      </Box.Content>
    </Box>
  );
};

export default RequestAccess;
export { RequestAccess, RequestAccessProps };