import React, { Fragment } from 'react';
import { ThemeProvider } from '@screentone/core';

const ThemeProviderWrapper: React.FC<{enableThemeOptions: boolean}> = ({
  enableThemeOptions,
  children
}) => {
  if (enableThemeOptions) {
    return (<ThemeProvider>{children}</ThemeProvider>);
  }
  return <Fragment>{children}</Fragment>
};

export default ThemeProviderWrapper;
