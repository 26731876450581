import React, { Fragment } from 'react';
// import { useHistory } from 'react-router-dom';
import { HeaderBar } from '@screentone/core';
// import get from 'lodash/get';
// import { useAuth } from '../../../providers/Auth';
import { MenuItemsProps } from '../../../types';

const UserMenus: React.FC<MenuItemsProps> = ({ items }) => {
  return (
    <Fragment>
      {items && items.map(({ type, ...itemProps }: any, i: any) => {
          if (type === 'link') {
            return (
              <HeaderBar.Settings.Link
                key={`Notifications.Link.${i}`}
                {...itemProps}
              >
                {itemProps.text}
              </HeaderBar.Settings.Link>
            );
          }
          return (
            <HeaderBar.Settings.Item
              key={`Notifications.Item.${i}`}
              {...itemProps}
            >
              {itemProps.content}
            </HeaderBar.Settings.Item>
          );
        })}
    </Fragment>
  );
};

export default UserMenus;
