import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageWrapper, Login, BrandSelect } from '@screentone/core';
import ErrorPage from '../Error';
import get from 'lodash/get';

import { useAuth } from '../../providers/Auth';
import LoadingPage from '../Loading';

interface LoadingProps {
  HomePage?: React.ElementType;
  LoginPage?: React.ElementType;
}

const Home: React.FC<LoadingProps> = ({ HomePage, LoginPage, ...props }) => {
  const { authState, app, user, login} = useAuth();
  const { isPending, isAuthenticated } = authState;

  if (isPending) {
    return <LoadingPage />;
  }

  if (isAuthenticated) {
    if (HomePage) {
      return <HomePage />;
    }
    if (!user) {
      return <LoadingPage />;
    }
    const assignedProperties: { key: string; name: string }[] = get(
      user,
      'app_properties',
      []
    );
    const propertyLength = assignedProperties.length;
    if (propertyLength === 1) {
      return <Redirect push to={`/${assignedProperties[0].key}`} />;
    }

    if (propertyLength > 1) {
      return (
        <BrandSelect>
          {assignedProperties.map(property => (
            <BrandSelect.Item
              key={property.key}
              type={property.key}
              componentEl="a"
              href={`/${property.key}`}
            />
          ))}
        </BrandSelect>
      );
    } else {
      console.error('Home: ', {
        error: 403,
        app,
        user: user,
        locationPathname: window.location.pathname,
      });
      // TODO: handle a case where the user is authenticated but there are no properties

      return <ErrorPage type="403" app={app} />;
    }
  }

  /**
   * When we reach this point with the way the useAuth works;
   * if the first condition is met the user does not have access
   * to this application and should be provided a request form.
   * Otherwise the user is not logged in and is presented to login.
   */
  
  if (LoginPage) {
    return <LoginPage {...props} />;
  }
  
  return (
    <PageWrapper>
      <Login
        name={app.name}
        onClick={login}
        illustration={app.illustration || 'no-access'}
      />
    </PageWrapper>
  );  
};

export default Home;
