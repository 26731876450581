import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import { OktaAuthService, AppConfig } from '../../types';
import * as constants from './Login.constants';
import { useMedia } from '../../hooks/useMedia';
import './Login.styles.css';

interface LoginProps {
  app: AppConfig;
  config: OktaAuthService;
}

const Login: React.FC<LoginProps> = ({ app, config }) => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef<HTMLDivElement | null>(null);
  const matches = useMedia('(max-width: 600px)');

  const appEnv = app.env || '';
  const isProd = constants.PROD_ALIASES.includes(appEnv.toLowerCase());
  const appName = `${app.name} ${!isProd && app.env ? ` - ${appEnv}` : ''}`;

  const [baseUrl] = config.issuer.split('/oauth2');
  const isPreview = baseUrl.split('.').includes('oktapreview');

  const scopes = config.scopes || constants.DEFAULT_SCOPES;

  const loginClass = !matches ? ' st_login_image' : '';
  const containerClass = `st_login_background_image${loginClass}`;

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const { clientId, redirectUri } = config;
    const authParams = { issuer: config.issuer, scopes };

    const appConfig = { baseUrl, clientId, redirectUri, authParams };
    const signIn = new OktaSignIn({ ...constants.WIDGET_CONFIG, ...appConfig });

    signIn
      .showSignInToGetTokens({ el: widgetRef.current, scopes })
      .then((tokens: string) => {
        // Add tokens to storage
        oktaAuth.handleLoginRedirect(tokens);
      })
      .catch((err: any) => {
        console.error('err: ', err);
        throw err;
      });

    signIn.on('afterError', async (context: { controller: any; }, error: { name: any; message: any; statusCode: any; }) => {
      console.log('afterError context: ', context);
      console.log('afterError error: ', error);
      const oauthErrorName = "OAUTH_ERROR";
      const accessDeniedMessage = "User is not assigned to the client application.";

      if (error.name === oauthErrorName && error.message === accessDeniedMessage) {
        const page = window.document.getElementById('login_page');
        if (page) page.style.visibility = "hidden";

        const session = await signIn.authClient.session.get();
        const user = await session.user();
        const { email = '',  firstName = '', lastName = '' } = user.profile;
        window.location.href = `/error/access_denied?name=${encodeURIComponent(`${firstName} ${lastName}`)}&email=${email.toLowerCase()}`;
      }

  });
    
    return () => signIn.remove();
  }, [oktaAuth]);

  const propertyLogoSrc = app.logo || constants.FAVICON_SRC;
  const oktaAccountDomain = isPreview ? ' (Okta Preview) ' : ' ';

  return (
    <div id="login_page">
      <div className={containerClass} />
      <div className="st_login_container">
        <div className="st_login_top_bar" />
        <div className="st_login_top_bar_text">
          <h1 className="st_login_h1">
            Connecting to
            <div className="st_logging_logo_container">
              <img src={propertyLogoSrc} alt={app.name} />
            </div>
          </h1>
          <p className="st_login_paragraph">
            Sign-in with your News Corp account
            {oktaAccountDomain} to access {appName}
          </p>
        </div>
      </div>
      <div ref={widgetRef}></div>
    </div>
  );
};

export default Login;
