import React, { useContext } from 'react';
import { HeaderBar, ThemeContext, Toggle, Typography } from '@screentone/core';
import { useHistory } from 'react-router-dom';
import MenuItems from './MenuItems';
import UserMenu from './UserMenu';
import NotificationsMenu from './NotificationsMenu';
import { useAuth } from '../../providers/Auth';
import { HeaderBarProps, HeaderBarObjectProps } from '../../types';

const ModeToggle: React.FC = () => {
  const {
    modeSetting,
    updateMode,
  } = useContext(ThemeContext);

  return (
    <div>
      <Typography variant="label1" weight="bold" componentEl="p">
        Theme
      </Typography>
      <Toggle onChange={(mode: string) => updateMode(mode)} value={modeSetting}>
        <Toggle.Item value="auto">System</Toggle.Item>
        <Toggle.Item value="light">Light</Toggle.Item>
        <Toggle.Item value="dark">Dark</Toggle.Item>
      </Toggle>
    </div>
  )
};

const HeaderBarComponent: React.FC<HeaderBarProps> = ({
  HeaderBarElement,
  HeaderBarObject,
  HeaderBarFunction,
  children,
  ...props
}) => {
  const { app, user, isProd, currentProperty, userAccess, enableThemeOptions } = useAuth();
  const history = useHistory();
  const products = ((user && user.app_properties) || []).map(userProperty => ({
    name: userProperty.name,
    value: userProperty.key,
    url: `/${userProperty.key}`,
  }));

  const onProductSwitch = app.onPropertySwitch
    ? app.onPropertySwitch
    : (product: string) => history.push(`/${product}`);

  let headerObj: HeaderBarObjectProps | undefined;
  if (HeaderBarFunction) {
    headerObj = HeaderBarFunction()[0];
  } else {
    headerObj = HeaderBarObject;
  }

  if (window.location.pathname === '/login') {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (HeaderBarElement) {
    return <HeaderBarElement {...props}>{children}</HeaderBarElement>;
  }

  interface FeedbackOptionsProps {
    slackUrl?: string;
    slackChannel?: string;
    email?: string;
    feedback?: string;
  }

  const feedbackOptions: FeedbackOptionsProps = {}
  if (app.slack) {
    feedbackOptions.slackUrl = app.slack.url;
    feedbackOptions.slackChannel = app.slack.name;
  }
  if (app.email) {
    feedbackOptions.email = app.email;
  }

  if (app.feedbackUrl) {
    feedbackOptions.feedback = app.feedbackUrl;
  }

  const appEnv = isProd ? null : app.env;
  
  return (
    <React.Fragment>
      {headerObj && headerObj.alert}
      <HeaderBar sticky={headerObj && headerObj.sticky}>
        <HeaderBar.Branding href={(headerObj && headerObj.brandingHref) || `/${currentProperty || ''}`} componentEl="a" env={appEnv}>
          {app.name}
        </HeaderBar.Branding>
        {headerObj && headerObj.links && (
          <HeaderBar.Links>
            {headerObj.links.map((link: any) => {
              const { text, href, restricted, ...props } = link;
              if (!restricted || (restricted && userAccess(restricted))) {
                return (
                  <HeaderBar.Links.Item key={href || text} href={href} {...props}>
                    {text}
                  </HeaderBar.Links.Item>
                );
              } else {
                return;
              }
            })}
          </HeaderBar.Links>
        )}

        {(Object.keys(feedbackOptions).length > 0 || (headerObj && headerObj.feedback)) && (
          <HeaderBar.Feedback {...feedbackOptions}>
            {headerObj && headerObj.feedback && headerObj.feedback.items && (
              <MenuItems items={headerObj.feedback.items} />
            )}
          </HeaderBar.Feedback>
        )}

        {(user && headerObj && headerObj.settings) || enableThemeOptions && (
          <HeaderBar.Settings>
            {headerObj && headerObj.settings && headerObj.settings.items && (
              <MenuItems items={headerObj.settings.items} />
            )}
            {enableThemeOptions && (
              <HeaderBar.Settings.Item>
                <ModeToggle />
              </HeaderBar.Settings.Item>
            )}
          </HeaderBar.Settings>
        )}
        {user && headerObj && headerObj.notifications && (
          <NotificationsMenu notifications={headerObj.notifications} />
        )}
        {user && <UserMenu HeaderBarObject={headerObj} />}
        {user && user.app_properties && user.app_properties.length > 1 && currentProperty && (
          <HeaderBar.Product
            product={currentProperty}
            products={products}
            onProductSwitch={onProductSwitch}
          />
        )}
      </HeaderBar>
    </React.Fragment>
  );
};

export default HeaderBarComponent;
