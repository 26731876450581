import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import ErrorPage from '../../pages/Error';
import LoadingPage from '../../pages/Loading';
import { useAuth } from '../../providers/Auth';

interface ProtectedRouteProps extends RouteProps {
  /**
  * Pass a string of the role to validate against the authenticated user.
  */
  restricted?:  boolean | string | Array<string>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  restricted,
  ...props
}) => {
  const { app, user, oktaAuth, authState, userAccess, login } = useAuth();

  if (!authState.isAuthenticated) {
    if (!authState.isPending) {
      oktaAuth.setOriginalUri();
      login();
    }
    return null;
  }

  if (!user) {
    return <LoadingPage />;
  }

  if (typeof props.path === 'string' && props.path.startsWith('/:property')) {
    const regexMatch = /^\/(?<property>.*?)\//g.exec(
      `${window.location.pathname}/`
    );

    let property: string;
    if (regexMatch !== null) {
      property = regexMatch?.groups?.property || '';
    }

    const userHasProperty = user.app_properties.some(
      userProperty => userProperty.key === property
    );

    if (!userHasProperty) {
      console.error('ProtectedRoute userHasProperty: ', {
        error: 403,
        user: user,
        locationPathname: window.location.pathname,
      });
      return <ErrorPage app={app} type="403" />;
    }
  }
  if (restricted && !userAccess(restricted)) {
    return <ErrorPage app={app} type="403" />;
  }

  const SecuredComponent = component as React.ElementType<RouteComponentProps>;

  return (
    <Route
      {...props}
      render={routeProps =>
        props.render ? (
          props.render({ ...props, ...routeProps })
        ) : (
          <SecuredComponent {...routeProps} />
        )
      }
    />
  );
};

export default ProtectedRoute;
export { ProtectedRoute };