import React, { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { checkUserAccess } from './AuthProvider.utils';
import {
  Maybe,
  AppConfig,
  User,
  AuthContextProps,
} from '../../types';

const AuthContext = React.createContext<Maybe<AuthContextProps>>(null);

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("Can't use without AuthProvider!");
  }
  return context;
}

interface AuthProviderProps {
  app: AppConfig;
}

const AuthProvider: React.FC<AuthProviderProps> = ({
  app,
  ...props
}) => {
  const { oktaAuth, authState, _onAuthRequired } = useOktaAuth();

  const routeMatch = useRouteMatch('/:property');
  const [user, setUser] = useState<User | undefined>(undefined);
  const [enableThemeOptions, setEnableThemeOptions] = useState<boolean | undefined>(undefined);

  const routePramsProperty = get(routeMatch, 'params.property', null);

  const appProperties = app.properties || [];

  const currentProperty = appProperties
    .map(property => property.key)
    .includes(routePramsProperty)
    ? routePramsProperty
    : null;

  const currentPropertyObject =
    currentProperty &&
    appProperties.filter(appProperty => appProperty.key === currentProperty)[0];

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then(async (returnedUser: User) => {
        if (!isEmpty(returnedUser)) {
          const authenticatedUser: User = {
            ...returnedUser,
            // Turn string property list into Okta properties
            app_properties: appProperties.filter(appProperty =>
              get(returnedUser, 'app_properties', []).some(
                // @ts-ignore
                (userProperty: string) => userProperty === appProperty.key
              )
            ),
          };
      
          const options = ((typeof app.useThemes === 'boolean' && app.useThemes)
            || app.useThemes === 'true'
            || checkUserAccess(authenticatedUser, [`${app.useThemes}`], app.userAccessKey));
          
          setEnableThemeOptions(options);
          setUser(authenticatedUser);
        }
      });
    } else {
      setUser(undefined);
      setEnableThemeOptions(undefined);
    }
  }, [app.properties, authState.isAuthenticated]);

  const userAccess = (permission?: boolean | string | Array<string>) => {
    if (user && permission) {
      if (typeof permission === 'boolean') {
        return permission;
      }  else {
        return checkUserAccess(
          user,
          permission = typeof permission === 'string' ? [permission] : permission,
          app.userAccessKey
        );
      } 
    }
    return false;
  };


  function loginFn() {
    if (_onAuthRequired) {
      _onAuthRequired();
    } else {
      oktaAuth
        .signInWithRedirect()
        .catch((error: any) => {
          console.error('Homepage signInWithRedirect error: ', error);
        });
    }
  }

  // https://developer.okta.com/docs/guides/sign-users-out/react/sign-out-of-your-app/
  function appLogout() {
    if (oktaAuth) {
      oktaAuth.tokenManager.clear();
      window.location.assign(`${window.location.origin}/logout?state=app`);
    }
  }

  function oktaSignOut() {
    if (oktaAuth) {
      oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + '/logout',
        state: 'okta'
      });
    }
  }

  const value = {
    authState,
    oktaAuth,
    user,
    app,
    currentProperty,
    currentPropertyObject,
    login: loginFn,
    appLogout,
    oktaSignOut,
    userAccess,
    isProd: ['prod', 'production'].includes((app.env || '').toLowerCase()),
    enableThemeOptions,
  };

  return <AuthContext.Provider value={value} {...props} />;
};

export { useAuth, AuthProvider, AuthContext };
export default AuthProvider;
