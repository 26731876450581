import { PublicationsProps, SimpleInputHook, RolesProps } from '../../types';

const getSlackRequestBody = (
  roles: RolesProps,
  publications: PublicationsProps,
  justification: SimpleInputHook,
  name: SimpleInputHook,
  email: SimpleInputHook,
  appName?: string,
  appEnv?: string
) => {
  let requestedApp = appName ? appName : 'an app';
  if (appName && appEnv) requestedApp += ` - ${appEnv}`;

  const attachmentBlocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: justification.value,
      },
    },
  ];

  if (publications.value.length) {
    attachmentBlocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Publications:* ${publications.value
          .map(p => `\`${p.value}\``)
          .join(' ')}`,
      },
    });
  }
  if (roles.value.length) {
    attachmentBlocks.push({
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Roles:* ${roles.value.map(p => `\`${p.value}\``).join(' ')}`,
      },
    });
  }

  return {
    attachments: [
      {
        blocks: attachmentBlocks,
        color: '#5755D9',
      },
    ],
    text: `*${name.value}* <${email.value}> is requesting access to ${requestedApp}`,
    fallback: `*${name.value}* <${email.value}> is requesting access to ${requestedApp}`,
    footer: 'Newsroom Development',
    ts: Math.floor(Date.now() / 1000),
  };
};

const getRequestBody = (
  name: SimpleInputHook,
  email: SimpleInputHook,
  justification: SimpleInputHook,
  roles: PublicationsProps,
  publications: RolesProps
) => {
  return {
    name: name.value,
    email: email.value,
    justification: justification.value,
    properties: JSON.stringify(publications.value.map(p => p.value)),
    roles: JSON.stringify(roles.value.map(p => p.value)),
  };
};

export { getSlackRequestBody, getRequestBody };
