import React from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import compareVersions from 'compare-versions';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import get from 'lodash/get';

import AuthProvider from '../providers/Auth';
import ThemeProvider from '../providers/Theme';

import HeaderBarComponent from '../components/HeaderBar';
import PageWrapperComponent from '../components/PageWrapper';
import AnnouncementBar from '../components/AnnouncementBar';
import ErrorPage from '../pages/Error';
import { ERRORS } from '../pages/Error/Error.constants'; //  USER_REQUEST_OPTIONS

import LoadingPage from '../pages/Loading';
import LoginPage from '../pages/Login';
import LogoutPage from '../pages/Logout';
import HomePage from '../pages/Home';
import ProtectedRoute from './ProtectedRoute';
import LoginCallback from './LoginCallback';
import { validateConfig } from './AuthRouter.utils';
import { AuthRouterProps } from '../types';

const currentVersion = '0.0.35'; // TODO: update to get version from package.json

const AuthRouter: React.FC<AuthRouterProps> = ({
  app,
  authConfig,
  useCustomLoginPage = false,
  provider,
  pageWrapper,
  headerBar,
  headerBarOverride,
  headerBarHook,
  homePage,
  loginPage,
  children,
}) => {
  const isValid = validateConfig({ app, authConfig });
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    const replaceUrl = toRelativeUrl(originalUri, window.location.origin)
    if (history) {
      history.replace(replaceUrl);
    } else {
      window.location.replace(replaceUrl)
    }
  };
  if (!isValid) {
    return <ThemeProvider enableThemeOptions={!!app.useThemes}><ErrorPage type="412" app={app} /></ThemeProvider>;
  }

  function getLocalStorage(storageId:string) {
    try {
      return localStorage.getItem(storageId);
    } catch (error) {
      return null;
    }
  }
  const savedVersion: string = getLocalStorage('st-aw-version') || '0.0.0';
  if (app.maintenance && app.maintenance.active) {
    return <ThemeProvider enableThemeOptions={!!app.useThemes}><ErrorPage type="maintenance" app={app} /></ThemeProvider>;
  }

  // TODO: Move to utils
  function cleanStoredOktaItems(
    obj: Storage | { removeItem?: any },
    isCookie?: boolean
  ) {
    Object.keys(obj).map(key => {
      if (key.includes('okta')) {
        isCookie
          ? (document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
          : obj.removeItem(key);
      }
    });
  }

  const clearOktaStorage = compareVersions.compare(
    currentVersion,
    savedVersion,
    '>'
  ); // return true

  if (clearOktaStorage) {
    try {

      const cookies = document.cookie.split(';').reduce((ac, str) => {
        const arr = str.trim().split('=');
        return { ...ac, [arr[0]]: arr[1] };
      }, {});

      cleanStoredOktaItems(localStorage);
      cleanStoredOktaItems(sessionStorage);
      cleanStoredOktaItems(cookies, true);

      localStorage.setItem('st-aw-version', currentVersion);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const AuthHandler = () => {
    window.location.assign(`${window.location.origin}/login`);
  };

  if (!authConfig.redirectUri) {
    authConfig.redirectUri = `${window.location.origin}/login/callback`
  }

  const expireEarlySeconds = get(authConfig, 'tokenManager.expireEarlySeconds');

  if (!expireEarlySeconds) {
    authConfig.tokenManager = authConfig.tokenManager || {};
    authConfig.tokenManager.expireEarlySeconds = 120;
  }

  function ErrorRoute() {
    const { type } = useParams<{ type: keyof typeof ERRORS | any }>();
    return <ErrorPage type={type} app={app} />;
  }

  function Error404Route() {
    return <ErrorPage type="404" app={app} />;
  }

  function HomePageRoute() {
    return <HomePage HomePage={homePage} LoginPage={loginPage} />;
  }

  let propertyPath = '';

  if (app.properties) {
    propertyPath =
      app.properties.length === 1
        ? `${app.properties[0].key}`
        : `:property(${app.properties
          .map((property: { key: string }) => property.key)
          .join('|')})`;
  }

  const childRoutes = React.Children.toArray(children).map(child => {
    if (React.isValidElement(child)) {
      const currentProps = child.props;
      const newProps: any = {};
      Object.keys(currentProps).forEach(key => {
        if (typeof currentProps[key] === 'string') {
          newProps[key] = currentProps[key].replace(':property', propertyPath);
        } else {
          newProps[key] = currentProps[key];
        }
      });
      return React.cloneElement(child, newProps);
    }
    return null;
  });

  const oktaAuth = new OktaAuth(authConfig);

  const SecondaryProvider = provider || 'span';
  
  const CustomLogin: React.FC = () => (
    <LoginPage config={authConfig} app={app} />
  );

  return (
    <ThemeProvider enableThemeOptions={!!app.useThemes}>
      <Router>
      {app.maintenance && app.maintenance.announcement && (
        <AnnouncementBar
          id={app.maintenance.announcement.id}
          content={app.maintenance.announcement.content}
        />
      )}
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={useCustomLoginPage ? AuthHandler : null}>
        <AuthProvider app={app}>
             <HeaderBarComponent
                HeaderBarElement={headerBarOverride}
                HeaderBarObject={headerBar}
                HeaderBarFunction={headerBarHook}
            />
            <SecondaryProvider>
              <PageWrapperComponent PageWrapperElement={pageWrapper}>
                <React.Suspense fallback={<LoadingPage />}>
                  <Switch>
                    {childRoutes}
                    <Route
                      exact
                      path="/(index.html)?"
                      component={HomePageRoute}
                    />
                    {useCustomLoginPage && (
                      <Route exact path="/login" component={CustomLogin} />
                    )}
                    <Route path="/login/callback" component={LoginCallback} />
                    <Route path="/logout" component={LogoutPage} />
                    <Route exact path="/error/:type?" component={ErrorRoute} />
                    <Route path="*" component={Error404Route} />
                  </Switch>
                </React.Suspense>
              </PageWrapperComponent>
            </SecondaryProvider>
        </AuthProvider>
      </Security>
    </Router>
    </ThemeProvider>
  );
};

export { AuthRouter, ProtectedRoute };
