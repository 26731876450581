import React from 'react';
import { HeaderBar } from '@screentone/core';
import get from 'lodash/get';
import { useAuth } from '../../../providers/Auth';
import { UserMenuProps } from '../../../types';

const UserMenu: React.FC<UserMenuProps> = ({ HeaderBarObject }) => {
  const { app, user, userAccess, oktaAuth, appLogout, oktaSignOut } = useAuth();

  if (!user) {
    return null;
  }

  const oktaURL = get(oktaAuth, 'options.issuer', '').split('/oauth2')[0];
  const isPreview = oktaURL.includes('oktaprevew.com');
  const loginSource = {
    text: `Okta${isPreview ? ' Preview': ''}`,
    url: oktaURL,
    settingUrl: `${oktaURL}/enduser/settings`,
  }

  return (
    <HeaderBar.User name={user.name} avatar={user.picture}>
      <HeaderBar.User.User
        name={user.name}
        avatar={user.picture}
        email={user.email}
        loginSource={loginSource}
      />


      {HeaderBarObject &&
        HeaderBarObject.userLinks &&
        HeaderBarObject.userLinks.map(option => {
          if (
            !option.restricted ||
            (option.restricted && userAccess(option.restricted))
          ) {
            return (
              <HeaderBar.User.Link
                key={option.href}
                href={option.href}
                componentEl="a"
                target={option.target || null}
                rel="noopener noreferrer"
              >
                {option.text}
              </HeaderBar.User.Link>
            );
          }
          return null;
        })}

      <HeaderBar.User.Link onClick={appLogout} data-testid="button-logout">
        Logout of {app.name}
      </HeaderBar.User.Link>
      <HeaderBar.User.Link onClick={oktaSignOut} data-testid="button-sign-out">
        Sign out of Okta
      </HeaderBar.User.Link>
      <HeaderBar.User.Footer version={app.version} credit="Screentone" />
    </HeaderBar.User>
  );
};

export default UserMenu;
