import { useEffect, useState } from 'react';

function useMedia(mqString: string) {
  const mqList = window.matchMedia(mqString);
  const [match, setMatch] = useState(mqList.matches);

  useEffect(() => {
    function listener(event: MediaQueryListEvent) {
      setMatch(event.matches);
    }

    mqList.addListener(listener);
    return () => mqList.removeListener(listener);
  }, [mqList]);

  return match;
}

export { useMedia };
